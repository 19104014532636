import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import BgImage from "../../images/cornville.jpg"
import Layout from "../../components/layoutElMirageCustom"
import PageHeaders from "../../components/pageHeaders"
import SEO from "../../components/seo"

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
`

const MainButton = styled(Link)`
  color: #fff;
  background: #f07525;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
`

const IndexPage = () => (
    <Layout
        ImgSrc={BgImage}
        MainText="Connect with Us"
        SecondText="We want to hear from you"
    >
        <SEO title="Connect with Us" />
        <iframe
            src="https://cccares.formstack.com/forms/connect_cornville"
            title="Connect - ElMirage"
            width="100%"
            height="1100px"
            frameBorder="0"
        ></iframe>
    </Layout>
)

export default IndexPage
